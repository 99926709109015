<template>
  <div>
    <b-card class="card-custom-class">
      <div class="closemodel">
        <button @click="$bvModal.hide(address._id)" type="button">
          <span><img src="img/address/close.png" alt="" /></span>
        </button>
      </div>
      <b-icon
        class="mr-1"
        icon="geo-alt-fill"
        scale="2"
        variant="success"
      ></b-icon>
      <span class="change-location-text">Edit Address </span>
    </b-card>
    <b-card>
      <v-form>
        <v-container>
          <ValidationObserver v-slot="{ invalid }">
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="Full name"
                >
                  <v-text-field
                    v-model="address.fullName"
                    label="Full name"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="House Number"
                >
                  <v-text-field
                    v-model="address.houseNumber"
                    label="House Number"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="Building, Company, Apartment"
                >
                  <v-text-field
                    v-model="address.street2"
                    label="Building, Company, Apartment"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required"
                  v-slot="{ errors }"
                  name="Area, Colony, Street, Sector, Village"
                >
                  <v-text-field
                    v-model="address.street1"
                    label="Area, Colony, Street, Sector, Village"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|numeric"
                  v-slot="{ errors }"
                  name="zip"
                >
                  <v-text-field
                    v-model="address.zip"
                    label="Zip"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|max:20|min:2"
                  v-slot="{ errors }"
                  name="City"
                >
                  <v-text-field
                    v-model="address.city"
                    label="City"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|max:20|min:2"
                  v-slot="{ errors }"
                  name="State"
                >
                  <v-text-field
                    v-model="address.state"
                    label="State"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|max:20|min:2"
                  v-slot="{ errors }"
                  name="Country"
                >
                  <v-text-field
                    v-model="address.country"
                    label="Country"
                  ></v-text-field>
                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="required|digits:10"
                  v-slot="{ errors }"
                  name="phone Number"
                >
                  <v-text-field
                    readonly
                    v-model="phoneNumber"
                    label="Phone Number"
                  ></v-text-field>

                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <ValidationProvider
                  rules="email"
                  v-slot="{ errors }"
                  name="Email"
                >
                  <v-text-field
                    v-model="email"
                    label="Email"
                    placeholder="Email"
                  ></v-text-field>

                  <span class="validate-error">{{ errors[0] }}</span>
                </ValidationProvider>
              </v-col>

              <v-col cols="12" sm="6" md="6">
                <b-form-select
                  v-model="address.category"
                  :selected="address.category"
                  placeholder="Select an Address Type "
                  name="Address Type"
                  id="Address Type"
                  class="form-control"
                  size="1"
                >
                  <option disabled value="Select an Address Type">
                    Select an Address Type
                  </option>
                  <option value="Home">Home (7 am – 9 pm delivery)</option>
                  <option value="Office">
                    Office/Commercial (10 AM - 6 PM delivery)
                  </option>
                  <option value="Other">Other</option>
                </b-form-select>
              </v-col>
              <v-col
                style="margin-top: 4px; font-size: 19px"
                cols="12"
                sm="6"
                md="6"
              >
                <b-row>
                  <b-col md="5">
                    <b-card-text class="price-details"
                      >Default Address</b-card-text
                    >
                  </b-col>
                  <b-col md="7">
                    <b-form-radio-group
                      v-model="address.isDefault"
                      id="radio-group-2"
                      name="radio-sub-component"
                    >
                      <b-form-radio size="lg" :value="true">Yes</b-form-radio>
                      <b-form-radio size="lg" :value="false">No</b-form-radio>
                    </b-form-radio-group>
                  </b-col>
                </b-row>
              </v-col>

              <b-col class="text-center">
                <template v-if="!isLoading">
                  <button
                    @click="$bvModal.hide(address._id)"
                    type="button"
                    class="btn btn-outline-primary btn-lg mr-3"
                  >
                    <span class="button-text"></span> Cancel
                  </button>
                  <button
                    @click="onSubmitAddress"
                    type="button"
                    :disabled="invalid"
                    class="btn btn-primary btn-lg"
                  >
                    <span class="button-text"></span> Save
                  </button>
                </template>
                <template v-if="isLoading">
                  <b-spinner
                    v-for="i in 3"
                    :key="i"
                    class="mr-1"
                    small
                    label="Small Spinner"
                    type="grow"
                  ></b-spinner>
                </template>
              </b-col>
            </v-row>
          </ValidationObserver>
        </v-container>
      </v-form>
    </b-card>
  </div>
</template>
<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    address: {
      address: () => {},
      type: Object,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    phoneNumber() {
      return this.address && this.address.contacts
        ? this.address.contacts[0].value
        : "";
    },
    email() {
      return this.address && this.address.contacts
        ? this.address.contacts[1].value
        : "";
    },
  },
  methods: {
    async onSubmitAddress() {
      this.isLoading = true;
      let payload = {
        fullName: this.address.fullName,
        houseNumber: this.address.houseNumber,
        street2: this.address.street2,
        street1: this.address.street1,
        zip: this.address.zip,
        city: this.address.city,
        state: this.address.state,
        country: this.address.country,
        phoneNumber: this.phoneNumber,
        email: this.email,
        category: this.address.category,
        isDefault: this.address.isDefault,
        id: this.address._id,
      };
      await this.$store
        .dispatch("address/editAddress", payload)
        .then((res) => {
          console.log(res);
          this.isLoading = false;
          this.$bvModal.hide(this.address._id);
          this.$swal({
            title: "Success!",
            text: "Address updated successfully",
            type: "success",
          });
        })
        .catch((err) => {
          console.log(err);
          this.isLoading = false;
          this.$bvModal.hide(this.address._id);
          this.$swal({
            title: "Error!",
            text: "Can not update address please try again",
            type: "error",
          });
        });
    },
  },
};
</script>
<style scoped>
.card-custom-class {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image-source: initial;
  border-image-slice: initial;
  border-image-width: initial;
  border-image-outset: initial;
  border-image-repeat: initial;
  border-radius: 0.25rem;
}
.list-group-modal {
  margin-top: 7px;
  max-height: 540px;
  margin-left: 10px;
  margin-right: 4px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.change-location-text {
  font-style: normal;
  font-weight: 520;
  font-size: 26px;
  line-height: 24px;
  margin-left: 5px;

  color: #2e2e2e;
}
.coupon-input {
  border: 0;
  border-bottom: 1px solid rgba(33, 33, 33, 0.08);
  outline: 0;
  width: 75%;
}
.text-danger {
  color: #fe7b08 !important;
  font-size: 14px;
  vertical-align: 5px;
}
.add-new-address-text {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #2a8750;
  cursor: pointer;
  position: absolute;
  right: 75px;
}
.address-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  text-transform: uppercase;

  color: #000000;
}
.body-class-modal {
  margin-top: -7px;
}

.address-details-text {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  color: #2e2e2e;
  margin-left: 37px;
  margin-top: 4px;
}

.my-class {
  background: black;
  color: white;
}
.closemodel {
  position: absolute;
  top: 8px;
  right: 3px;
}

.default-address-class {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #717171;
  margin-left: 10px;
}
</style>
